var abp = abp || {};
(function () {

    /* DEFAULTS *************************************************/

    var defaultOptions = {
        position: 'bottom-start',
        showConfirmButton: false,
        timer: 5000, //TO-DO Harman when upgrading framework, set this at 5000
        toast: true,
        timerProgressBar: true
    };

    /* NOTIFICATION *********************************************/

    var showNotification = function (type, message, title, options) {
        options.icon = type;
        if (title) {
            options.titleText = title;
            options.text = message;
        } else {
            options.titleText = message;
        }

        options.customClass = {
            title: 'kt-shape-font-color-1',
            icon: 'mr-2 kt-shape-font-color-1',
            content: 'kt-shape-font-color-1'
        }
        var combinedOptions = Object.assign({}, defaultOptions, options);
        Swal.fire(combinedOptions);
    };

    abp.notify.success = function (message, title, options) {
        showNotification('success', message, title,
            Object.assign({
                background: '#34bfa3',
            }, options));
    };

    abp.notify.info = function (message, title, options) {
        showNotification('info', message, title, Object.assign({
            background: '#36a3f7',
        }, options));
    };

    abp.notify.warn = function (message, title, options) {
        showNotification('warning', message, title, Object.assign({
            background: '#ffb822',
        }, options));
    };

    abp.notify.error = function (message, title, options) {
        showNotification('error', message, title, Object.assign({
            background: '#f4516c',
        }, options));
    };

})();
